<template>
  <div class="modal fade" :id="id" tabindex="-1"
       :data-bs-backdrop="noStatic ? 'true' : 'static'"
       aria-hidden="true" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
    <div class="modal-dialog modal-dialog-centered" :class="modalSize">
      <div class="modal-content">
        <div v-if="showHeader !== false" class="modal-header pb-0">
          <h5 class="modal-title">{{ name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>

        <div class="modal-body">
          <slot/>
        </div>

        <div v-if="showFooter !== false" class="modal-footer pt-0">
          <button type="button" class="btn btn-stop" data-bs-dismiss="modal">{{ $t('Close') }}</button>
          <span class="d-flex">
            <slot name="footer-btn"></slot>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      name: String,
      id: String,
      showFooter: Boolean,
      showHeader: Boolean,
      noStatic: Boolean,
      modalSize: String,
    },
  };
</script>

<style scoped>
  .modal-body {
    padding: 1rem 1.5rem !important;
  }
  .modal-header {
    border-bottom: 0px solid #dee2e6;
  }
  .modal-footer {
    border-top: 0px solid;
    padding: 1rem 1.5rem !important;
    justify-content: space-between;
  }
  .modal-title {
    font-weight: 600;
    font-size: larger;
  }
  .btn-close {
    margin: inherit !important;
    font-size: 14px;
  }
  .btn-stop {
    height: 40px;
    padding: 9px 16px;
    border-radius: 5px;
    background-color: #fbe5e8;
    color: #a8031c;
    font-size: 14px;
    font-weight: 700;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .btn-stop:hover {
    background-color: #a8031c;
    color: #fff;
  }
</style>
